<template>
  <div v-frag>
    <div
      id="message"
      class="fullwidth"
    >
      <div
        v-if="error"
        class="ui negative message"
      >
        <p><i class="cross icon" /> {{ error }}</p>
      </div>
    </div>
    <div class="fourteen wide column">
      <div
        :class="{ active: loading }"
        class="ui inverted dimmer"
      >
        <div class="ui loader" />
      </div>
      <form
        v-if="project"
        id="form-type-edit"
        action=""
        method="post"
        enctype="multipart/form-data"
        class="ui form"
      >
        <h1 v-if="action === 'create'">
          Créer un nouveau type de signalement pour le projet "{{
            project.title
          }}"
        </h1>
        <h1 v-if="feature_type && action === 'edit'">
          Éditer le type de signalement "{{ feature_type.title }}" pour le
          projet "{{ project.title }}"
        </h1>
        <p v-if="action === 'create'">
          Ces champs par défaut existent pour tous les types de signalement:
        </p>

        <div class="two fields">
          <div class="required field">
            <label :for="form.title.id_for_label">{{ form.title.label }}</label>
            <input
              :id="form.title.id_for_label"
              v-model="form.title.value"
              type="text"
              required
              :maxlength="form.title.field.max_length"
              :name="form.title.html_name"
              @blur="updateStore"
            >
            <ul
              id="errorlist"
              class="errorlist"
            >
              <li
                v-for="err in form.title.errors"
                :key="err"
              >
                {{ err }}
              </li>
            </ul>
          </div>

          <div class="required field">
            <label :for="form.geom_type.id_for_label">{{
              form.geom_type.label
            }}</label>
            <Dropdown
              :options="geomTypeChoices"
              :selected="selectedGeomType"
              :selection.sync="selectedGeomType"
            />
          </div>

          <div
            v-if="selectedGeomType !== 'Point'"
            class="required field"
          >
            <label :for="form.color.id_for_label">{{ form.color.label }}</label>
            <input
              :id="form.color.id_for_label"
              v-model="form.color.value"
              type="color"
              required
              style="width: 100%; height: 38px"
              :name="form.color.html_name"
              @blur="updateStore"
            >
          </div>
        </div>
        <div class="field">
          <div class="ui checkbox">
            <input
              :id="form.title_optional.html_name"
              v-model="form.title_optional.value"
              class="hidden"
              :name="form.title_optional.html_name"
              type="checkbox"
            >
            <label :for="form.title_optional.html_name">{{ form.title_optional.label }}</label>
          </div>
        </div>

        <!--  //* s'affiche après sélection d'option de type liste dans type de champ -->
        <div
          v-if="colorsStyleList.length > 0 && selectedGeomType !== 'Point'"
          id="id_style_container"
          class="custom_style"
        >
          <div
            id="id_list_selection"
            class="list_selection"
          >
            <Dropdown
              :options="colorsStyleList"
              :selected="selected_colors_style"
              :selection.sync="selected_colors_style"
              :placeholder="'Sélectionner la liste de valeurs'"
            />
          </div>
          <div
            id="id_colors_selection"
            class="colors_selection"
            hidden
          >
            <div
              v-for="(value, key, index) in form.colors_style.value.colors"
              :key="'colors_style-' + index"
            >
              <div
                v-if="key"
                class="color-input"
              >
                <label>{{ key }}</label><input
                  :name="key"
                  type="color"
                  :value="value"
                  @input="setColorStyles"
                >
              </div>
            </div>
          </div>
        </div>

        <span v-if="action === 'duplicate' || action === 'edit'" />

        <div id="formsets">
          <FeatureTypeCustomForm
            v-for="customForm in customForms"
            :key="customForm.dataKey"
            ref="customForms"
            :data-key="customForm.dataKey"
            :custom-form="customForm"
            :selected-color-style="form.colors_style.value.custom_field_name"
            @update="updateColorsStyle($event)"
          />
        </div>

        <button
          id="add-field"
          type="button"
          class="ui compact basic button"
          @click="addCustomForm"
        >
          <i class="ui plus icon" />Ajouter un champ personnalisé
        </button>

        <div class="ui divider" />
        <button
          class="ui teal icon button margin-25"
          type="button"
          @click="sendFeatureType"
        >
          <i class="white save icon" />
          {{ action === "create" ? "Créer" : "Sauvegarder" }} le type de
          signalement
        </button>
        <button
          v-if="geojson"
          class="ui teal icon button margin-25"
          type="button"
          @click="postFeatureTypeThenFeatures"
        >
          <i class="white save icon" />
          Créer et importer le(s) signalement(s) du geojson
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import frag from 'vue-frag';
import { mapGetters, mapState } from 'vuex';
import Dropdown from '@/components/Dropdown.vue';
import FeatureTypeCustomForm from '@/components/feature_type/FeatureTypeCustomForm.vue';

export default {
  name: 'FeatureTypeEdit',

  directives: {
    frag,
  },

  components: {
    Dropdown,
    FeatureTypeCustomForm,
  },

  props: {
    geojson: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      action: 'create',
      dataKey: 0,
      error: null,
      geomTypeChoices: [
        { value: 'linestring', name: 'Ligne' },
        { value: 'point', name: 'Point' },
        { value: 'polygon', name: 'Polygone' },
      ],
      form: {
        colors_style: {
          fields: [],
          value: {
            colors: {},
            custom_field_name: '',
          },
        },
        color: {
          id_for_label: 'couleur',
          label: 'Couleur',
          field: {
            max_length: 128, // ! Vérifier la valeur dans django
          },
          html_name: 'couleur',
          value: '#000000',
        },
        title: {
          errors: [],
          id_for_label: 'title',
          label: 'Titre',
          field: {
            max_length: 128, // ! Vérifier la valeur dans django
          },
          html_name: 'title',
          value: null,
        },
        title_optional: {
          errors: null,
          id_for_label: 'title_optional',
          html_name: 'title_optional',
          label: 'Titre du signalement optionnel',
          value: false,
        },
        geom_type: {
          id_for_label: 'geom_type',
          label: 'Type de géométrie',
          field: {
            max_length: 128, // ! Vérifier la valeur dans django
          },
          html_name: 'geom_type',
          value: 'point',
        },
      },
      slug: this.$route.params.slug,
      reservedKeywords: [
        // todo : add keywords for mapstyle (strokewidth...)
        'title',
        'description',
        'status',
        'created_on',
        'updated_on',
        'archived_on',
        'deletion_on',
        'feature_type',
        'display_creator',
        'display_last_editor',
        'project',
        'creator',
      ],
    };
  },

  computed: {
    ...mapState('projects', ['project']),
    ...mapState('feature_type', ['customForms', 'colorsStyleList', 'fileToImport']),
    ...mapGetters('feature_type', ['feature_type']),
    selectedGeomType: {
      get() {
        const currentGeomType = this.geomTypeChoices.find(
          (el) => el.value === this.form.geom_type.value
        );
        if (currentGeomType) {
          return currentGeomType ? currentGeomType.name : null;
        }
        return null;
      },
      set(newValue) {
        this.form.geom_type.value = newValue.value;
        this.form = { ...this.form }; // ! quick & dirty fix for getter not updating because of Vue caveat https://vuejs.org/v2/guide/reactivity.html#For-Objects
        this.updateStore();
      },
    },
    selected_colors_style: {
      get() {
        const name = this.form.colors_style.value.custom_field_name;
        const customField = this.customForms.find((el) => el.name === name);
        return customField && customField.length !== 0
          ? customField.label
          : name;
      },
      set(newValue) {
        //* update only if different than custom_form
        if (newValue.value !== this.form.colors_style.value.custom_field_name) {
          //* get back values from original feature_type
          if (
            this.feature_type && //* if the feature_type exists already
            newValue.value === this.feature_type.colors_style.custom_field_name
          ) {
            this.form.colors_style.value = this.feature_type.colors_style;
          } else {
            const newColorsStyle = {
              colors: newValue.options.reduce((obj, key) => {
                obj[key] = '#000000';
                return obj;
              }, {}),
            };
            this.form.colors_style.value = newColorsStyle;
            this.updateStore();
          }
        }
        this.form.colors_style.value.custom_field_name = newValue.value;
        this.form = { ...this.form }; //! force reactivity to update custom_field_name
      },
    },
  },

  watch: {
    feature_type(newValue) {
      if (newValue) {
        this.fillFormData(newValue);
      }
    },
    customForms(newValue, oldValue) {
      if (newValue !== oldValue) {
        const name = this.form.colors_style.value.custom_field_name;
        const customField = this.customForms.find((el) => el.name === name);
        if (!customField || customField.length === 0) {
          //* if the customForm corresponding doesn't exist reset colors_style values
          this.form.colors_style.value = {
            colors: {},
            custom_field_name: '',
          };
        }
      }
    },
  },

  created() {
    if (!this.project) {
      this.$store.dispatch('projects/GET_PROJECT', this.$route.params.slug);
      this.$store.dispatch('projects/GET_PROJECT_INFO', this.$route.params.slug);
    }
    this.$store.commit(
      'feature_type/SET_CURRENT_FEATURE_TYPE_SLUG',
      this.$route.params.slug_type_signal
    );

    this.definePageType();
  },

  mounted() {
    if (this.action === 'edit' || this.action === 'duplicate') {
      if (this.feature_type) {
        //* add datas from store to state to avoid mutating directly store with v-model (not good practice), could have used computed with getter and setter as well
        this.fillFormData(this.feature_type);
      }
      if (this.action === 'duplicate') {
        //* replace original name with new default title
        this.form.title.value += ` (Copie-${new Date()
          .toLocaleString()
          .slice(0, -3)
          .replace(',', '')})`;
        this.updateStore(); // * initialize form in store in case this.form would not be modified
      }
    }
    //* when creation from a geojson
    if (this.geojson) {
      this.importGeoJsonFeatureType();
      if (this.fileToImport && this.fileToImport.name) {
        this.form.title.value = // * use the filename as title by default
        this.fileToImport.name.split('.')[0];
      } else { //* case when the geojson comes from datasud catalog
        this.form.title.value = this.geojson.name;// * use the typename as title by default
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('feature_type/EMPTY_FORM');
    this.$store.commit('feature_type/EMPTY_CUSTOM_FORMS');
    this.$store.commit(
      'feature_type/SET_FILE_TO_IMPORT',
      null
    );
  },

  methods: {
    definePageType() {
      if (this.$router.history.current.name === 'ajouter-type-signalement') {
        this.action = 'create';
      } else if (
        this.$router.history.current.name === 'editer-type-signalement'
      ) {
        this.action = 'edit';
      } else if (
        this.$router.history.current.name === 'dupliquer-type-signalement'
      ) {
        this.action = 'duplicate';
      }
    },

    addCustomForm(customForm) {
      if (
        customForm &&
        this.customForms.some((cf) => cf.name === customForm.name)
      ) {
        //* abort if customForm already exists (because watcher can update again)
        return;
      }
      this.dataKey += 1; // * increment counter for key in v-for
      let newCustomForm = {
        dataKey: this.dataKey,
      };
      if (customForm) {
        //* if adding an existing customForm -> add its property to newCustomForm containing only dataKey
        newCustomForm = { ...newCustomForm, ...customForm };
      }
      this.$store.commit('feature_type/ADD_CUSTOM_FORM', newCustomForm); // * create an object with the counter in store
    },

    fillFormData(formData) {
      for (const el in formData) {
        // * find feature_type and fill form values
        if (this.form[el]) this.form[el].value = formData[el];
      }
      //! add custom fields using ONLY this function, incrementing dataKey for Vue to correctly update components
      formData.customfield_set.forEach((el) => this.addCustomForm(el));
      this.updateStore();
    },

    setColorStyles(event) {
      const { name, value } = event.target;
      this.form.colors_style.value.colors[name] = value;
    },

    updateColorsStyle(newOptions) {
      const optionNames = Object.keys(this.form.colors_style.value.colors);
      //* if new value added
      if (newOptions.length > optionNames.length) {
        for (const key of newOptions) {
          if (key && !optionNames.includes(key)) {
            //* check if key is not en empty string
            this.form.colors_style.value.colors[key] = '#000000'; //* add new entry
          }
        }
        //* if modified or deleted
      } else {
        let modifiedColorStyle = {};
        for (const [index, key] of newOptions.entries()) {
          //* if no key then item will disappear (deleted)
          if (key) {
            const values = Object.values(this.form.colors_style.value.colors);
            modifiedColorStyle[key] = values[index]; //* overide key and get previous value from previous colors style
          }
        }
        this.form.colors_style.value.colors = modifiedColorStyle;
      }
    },

    updateStore() {
      this.$store.commit('feature_type/UPDATE_FORM', {
        color: this.form.color,
        title: this.form.title,
        title_optional: this.form.title_optional,
        geom_type: this.form.geom_type,
        colors_style: this.form.colors_style,
      });
    },

    checkCustomForms() {
      let is_valid = true;
      if (this.$refs.customForms)
        for (const customForm of this.$refs.customForms) {
          if (customForm.checkCustomForm() === false) {
            is_valid = false;
          }
        }
      return is_valid; //* fallback if all customForms returned true
    },

    checkForms() {
      if (this.form.title.value) {
        this.form.title.errors = [];
        return this.checkCustomForms(); //* if customForms are ok, validate, if get out function
      } else if (
        !this.form.title.errors.includes('Veuillez compléter ce champ.')
      ) {
        this.form.title.errors.push('Veuillez compléter ce champ.');
        document
          .getElementById('errorlist')
          .scrollIntoView({ block: 'end', inline: 'nearest' });
      }
      return false;
    },

    goBackToProject(message) {
      this.$router.push({
        name: 'project_detail',
        params: {
          slug: this.slug,
          message,
        },
      });
    },

    sendFeatureType() {
      // * si édition d'une feature_type déja existante, faire un put
      const requestType = this.action === 'edit' ? 'put' : 'post';
      if (this.checkForms()) {
        this.$store
          .dispatch('feature_type/SEND_FEATURE_TYPE', requestType)
          .then(({ status }) => {
            if (status === 200) {
              this.goBackToProject('Le type de signalement a été mis à jour');
            } else if (status === 201) {
              this.goBackToProject('Le nouveau type de signalement a été créé');
            } else {
              this.displayMessage(
                "Une erreur est survenue lors de l'import du type de signalement"
              );
            }
          });
      }
    },

    postFeatures(feature_type_slug) {
      this.$store
        .dispatch('feature_type/SEND_FEATURES_FROM_GEOJSON', {
          slug: this.slug,
          feature_type_slug,
          geojson: this.geojson
        })
        .then((response) => {
          if (response && response.status === 200) {
            this.goBackToProject();
          } else {
            this.displayMessage(
              "Une erreur est survenue lors de l'import de signalements.\n " +
                response.data.detail
            );
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async postFeatureTypeThenFeatures() {
      const requestType = this.action === 'edit' ? 'put' : 'post';
      if (this.checkForms()) {
        this.loading = true;
        await this.$store
          .dispatch('feature_type/SEND_FEATURE_TYPE', requestType)
          .then(({ feature_type_slug }) => {
            if (feature_type_slug) {
              this.postFeatures(feature_type_slug);
            } else {
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    displayMessage(message) {
      this.error = message;
      document
        .getElementById('message')
        .scrollIntoView({ block: 'end', inline: 'nearest' });
    },

    // ****** Methodes for geojson import ****** //
    toNewFeatureType() {
      this.$router.push({
        name: 'ajouter-type-signalement',
        params: { geojson: this.jsonDict },
      });
    },

    translateLabel(value) {
      if (value === 'LineString') {
        return 'linestring';
      } else if (value === 'Polygon' || value === 'MultiPolygon') {
        return 'polygon';
      }
      return 'point';
    },

    transformProperties(prop) {
      const type = typeof prop;
      const date = new Date(prop);
      if (type === 'boolean') {
        return 'boolean';
      } else if (Number.isSafeInteger(prop)) {
        return 'integer';
      } else if (
        type === 'string' &&
        date instanceof Date &&
        !isNaN(date.valueOf())
      ) {
        return 'date';
      } else if (type === 'number' && !isNaN(parseFloat(prop))) {
        return 'decimal';
      }
      return 'char'; //* string by default, most accepted type in database
    },

    importGeoJsonFeatureType() {
      if (this.geojson.features && this.geojson.features.length) {
        //* in order to get feature_type properties, the first feature is enough
        const { properties, geometry } = this.geojson.features[0];
        this.form.title.value = properties.feature_type;
        this.form.geom_type.value = this.translateLabel(geometry.type);
        this.updateStore(); //* register title & geom_type in store

        //* loop properties to create a customForm for each of them
        for (const [key, val] of Object.entries(properties)) {
          //* check that the property is not a keyword from the backend or map style
          // todo: add map style keywords
          if (!this.reservedKeywords.includes(key)) {
            const customForm = {
              label: { value: key || '' },
              name: { value: key || '' },
              position: this.dataKey, // * use dataKey already incremented by addCustomForm
              field_type: { value: this.transformProperties(val) }, // * guessed from the type
              options: { value: [] }, // * not available in export
            };
            this.addCustomForm(customForm);
          }
        }
      }
    },
  },
};
</script>

<style>
#add-field {
  margin-top: 1em;
}

#id_style_container {
  display: flex;
  height: 100%;
}

#id_colors_selection {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.color-input {
  margin-left: 1em;
}
.color-input > label {
  margin-right: 0.5em;
}

.margin-25 {
  margin: 0 0.25em 0.25em 0 !important;
}

/* // * probleme avec le style récupéré, n'est jamais identique !???? */
#formsets {
  margin-top: 1em !important;
}
</style>