<template>
  <div class="ui teal segment pers-field">
    <h4>
      Champ personnalisé
      <button
        class="ui small compact right floated icon button remove-field"
        type="button"
        @click="removeCustomForm()"
      >
        <i class="ui times icon" />
      </button>
    </h4>
    <div class="visible-fields">
      <div class="two fields">
        <div class="required field">
          <label :for="form.label.id_for_label">{{ form.label.label }}</label>
          <input
            :id="form.label.id_for_label"
            v-model="form.label.value"
            type="text"
            required
            :maxlength="form.label.field.max_length"
            :name="form.label.html_name"
            @blur="updateStore"
          >
          <small>{{ form.label.help_text }}</small>
          <ul
            id="errorlist"
            class="errorlist"
          >
            <li
              v-for="error in form.label.errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div class="required field">
          <label :for="form.name.id_for_label">{{ form.name.label }}</label>
          <input
            :id="form.name.id_for_label"
            v-model="form.name.value"
            type="text"
            required
            :maxlength="form.name.field.max_length"
            :name="form.name.html_name"
            @blur="updateStore"
          >
          <small>{{ form.name.help_text }}</small>
          <ul
            id="errorlist"
            class="errorlist"
          >
            <li
              v-for="error in form.name.errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </div>
      </div>

      <div class="three fields">
        <div class="required field">
          <label :for="form.position.id_for_label">{{
            form.position.label
          }}</label>
          <div class="ui input">
            <input
              :id="form.position.id_for_label"
              v-model="form.position.value"
              type="number"
              :min="form.position.field.min_value"
              :name="form.position.html_name"
              @change="updateStore"
            >
          </div>
          <small>{{ form.position.help_text }}</small>
          <ul
            id="errorlist"
            class="errorlist"
          >
            <li
              v-for="error in form.position.errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div class="required field">
          <label :for="form.field_type.id_for_label">{{
            form.field_type.label
          }}</label>
          <Dropdown
            :disabled="!form.label.value || !form.name.value"
            :options="fieldTypeChoices"
            :selected="selectedFieldType"
            :selection.sync="selectedFieldType"
          />
          <ul
            id="errorlist"
            class="errorlist"
          >
            <li
              v-for="error in form.field_type.errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div
          v-if="selectedFieldType === 'Liste de valeurs'"
          class="field field-list-options required field"
        >
          <label :for="form.options.id_for_label">{{
            form.options.label
          }}</label>
          <input
            :id="form.options.id_for_label"
            v-model="arrayOption"
            type="text"
            :maxlength="form.options.field.max_length"
            :name="form.options.html_name"
            class="options-field"
          >
          <small>{{ form.options.help_text }}</small>
          <ul
            id="errorlist"
            class="errorlist"
          >
            <li
              v-for="error in form.options.errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';

export default {
  name: 'FeatureTypeCustomForm',

  components: {
    Dropdown,
  },

  props: {
    customForm: {
      type: Object,
      default: null,
    },
    selectedColorStyle: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      fieldTypeChoices: [
        { name: 'Booléen', value: 'boolean' },
        { name: 'Chaîne de caractères', value: 'char' },
        { name: 'Date', value: 'date' },
        { name: 'Liste de valeurs', value: 'list' },
        { name: 'Nombre entier', value: 'integer' },
        { name: 'Nombre décimal', value: 'decimal' },
        { name: 'Texte multiligne', value: 'text' },
      ],
      form: {
        dataKey: 0,
        label: {
          errors: [],
          id_for_label: 'label',
          label: 'Label',
          help_text: 'Nom en language naturel du champ',
          html_name: 'label',
          field: {
            max_length: 128,
          },
          value: null,
        },
        name: {
          errors: [],
          id_for_label: 'name',
          label: 'Nom',
          html_name: 'name',
          help_text:
            "Nom technique du champ tel qu'il apparaît dans la base de données ou dans l'export GeoJSON. Seuls les caractères alphanumériques et les traits d'union sont autorisés: a-z, A-Z, 0-9, _ et -)",
          field: {
            max_length: 128,
          },
          value: null,
        },
        position: {
          errors: [],
          id_for_label: 'position',
          label: 'Position',
          min_value: 0, // ! check if good values (not found)
          html_name: 'position',
          help_text:
            "Numéro d'ordre du champ dans le formulaire de saisie du signalement",
          field: {
            max_length: 128, // ! check if good values (not found)
          },
          value: this.customForm.dataKey - 1,
        },
        field_type: {
          errors: [],
          id_for_label: 'field_type',
          label: 'Type de champ',
          html_name: 'field_type',
          help_text: '',
          field: {
            max_length: 50,
          },
          value: 'boolean',
        },
        options: {
          errors: [],
          id_for_label: 'options',
          label: 'Options',
          html_name: 'options',
          help_text: 'Valeurs possibles de ce champ, séparées par des virgules',
          field: {
            max_length: 256,
          },
          value: [],
        },
      },
    };
  },

  computed: {
    selectedFieldType: {
      // getter
      get() {
        const currentFieldType = this.fieldTypeChoices.find(
          (el) => el.value === this.form.field_type.value
        );
        if (currentFieldType) {
          return currentFieldType.name;
        }
        return null;
      },
      // setter
      set(newValue) {
        this.form.field_type.value = newValue.value;
        this.form = { ...this.form }; // ! quick & dirty fix for getter not updating because of Vue caveat https://vuejs.org/v2/guide/reactivity.html#For-Objects
        // Vue.set(this.form.field_type, "value", newValue.value); // ? vue.set didn't work, maybe should flatten form ?
        this.updateStore();
      },
    },
    arrayOption: {
      get() {
        return this.form.options.value.join();
      },
      // * create an array, because backend expects an array
      set(newValue) {
        this.form.options.value = this.trimWhiteSpace(newValue).split(',');
        if (!this.hasDuplicateOptions()) {
          this.updateStore();
        }
      },
    },
  },

  mounted() {
    //* add datas from store to state to avoid mutating directly store with v-model (not good practice), could have used computed with getter and setter as well
    this.fillCustomFormData(this.customForm);
  },

  methods: {
    hasDuplicateOptions() {
      this.form.options.errors = [];
      const isDup =
        new Set(this.form.options.value).size !==
        this.form.options.value.length;
      if (isDup) {
        this.form.options.errors = ['Veuillez saisir des valeurs différentes'];
        return true;
      }
      return false;
    },

    fillCustomFormData(customFormData) {
      for (let el in customFormData) {
        if (el && this.form[el] && customFormData[el]) {
          //* check if is an object, because data from api is a string, while import from django is an object
          this.form[el].value = customFormData[el].value
            ? customFormData[el].value
            : customFormData[el];
        }
      }
      this.updateStore();
    },

    removeCustomForm() {
      this.$store.commit(
        'feature_type/REMOVE_CUSTOM_FORM',
        this.customForm.dataKey
      );
    },

    updateStore() {
      const data = {
        dataKey: this.customForm.dataKey,
        label: this.form.label.value,
        name: this.form.name.value,
        position: this.form.position.value,
        field_type: this.form.field_type.value,
        options: this.form.options.value,
      };
      this.$store.commit('feature_type/UPDATE_CUSTOM_FORM', data);
      if (this.customForm.name === this.selectedColorStyle ) {
        this.$emit('update', this.form.options.value);
      }
    },

    trimWhiteSpace(string) {
      // TODO : supprimer les espaces pour chaque option au début et à la fin QUE à la validation
      return string.replace(/\s*,\s*/gi, ',');
    },

    hasRegularCharacters(input) {
      for (let char of input) {
        if (!/[a-zA-Z0-9-_]/.test(char)) {
          return false;
        }
      }
      return true;
    },

    checkUniqueName() {
      const occurences = this.$store.state.feature_type.customForms
        .map((el) => el.name)
        .filter((el) => el === this.form.name.value);
      return occurences.length === 1;
    },

    checkFilledOptions() {
      if (this.form.field_type.value === 'list') {
        if (this.form.options.value.length < 1) {
          return false;
        } else if (
          this.form.options.value.length === 1 &&
          this.form.options.value[0] === ''
        ) {
          return false;
        }
      }
      return true;
    },

    checkCustomForm() {
      this.form.label.errors = [];
      this.form.name.errors = [];
      this.form.options.errors = [];
      if (!this.form.label.value) {
        //* vérifier que le label est renseigné
        this.form.label.errors = ['Veuillez compléter ce champ.'];
        return false;
      } else if (!this.form.name.value) {
        //* vérifier que le nom est renseigné
        this.form.name.errors = ['Veuillez compléter ce champ.'];
        return false;
      } else if (!this.hasRegularCharacters(this.form.name.value)) {
        //* vérifier qu'il n'y a pas de caractères spéciaux
        this.form.name.errors = [
          'Veuillez utiliser seulement les caratères autorisés.',
        ];
        return false;
      } else if (!this.checkUniqueName()) {
        //* vérifier si les noms sont pas dupliqués
        this.form.name.errors = [
          'Les champs personnalisés ne peuvent pas avoir des noms similaires.',
        ];
        return false;
      } else if (!this.checkFilledOptions()) {
        //* s'il s'agit d'un type liste, vérifier que le champ option est bien renseigné
        this.form.options.errors = ['Veuillez compléter ce champ.'];
        return false;
      } else if (this.hasDuplicateOptions()) {
        //* pour le cas d'options dupliqués
        return false;
      }
      return true;
    },
  },
};
</script>
